<template>
  <div class="useral-custom-element-multiple-select">
    <label v-if="label">{{ label }}</label>
    <div class="selected" :class="{ 'open': open, 'disabled': disabled, 'danger': danger }" :style="styles">

      <div @click="!disabled ? open = true: ''">
        {{
          selected.join(',')
        }}
      </div>
    </div>

    <div class="search-box"
         :class="{ selectHide: !open }"
         v-if="searchable">
      <vs-icon class="cancel-search"
               icon="icon-x"
               icon-pack="feather"
               v-if="searchable && search"
               @click="search = '', !disabled ? open = true : ''"/>

      <input type="text"
             class="useral-custom-element-input w-full p-0"
             @input="$emit('searched', $event)"
             @click="!disabled ? open = true : ''"
             v-model="search">

      <vs-icon class="search-icon"
               icon="icon-search"
               icon-pack="feather"/>
    </div>

    <div class="items" :class="{ selectHide: !open, 'searchable': searchable }">

      <vs-checkbox v-for="(option, i) of options"
                   :key="i"
                   v-model="selected"
                   :vs-value="typeof option == 'object' ? option.value.toString() : JSON.parse(option).value.toString()"
                   @change="$emit('change', $event)"
                   v-show="!option.hasOwnProperty('show') || option.show">
        {{ typeof option === 'string' ? JSON.parse(option).label : option.label }}
      </vs-checkbox>
    </div>

    <div class="custom-select-overflow" v-if="open" @click="open = false"></div>
  </div>
</template>

<script>
export default {
  name: 'customMultipleSelect',
  data () {
    return {
      selectTimer: 0,
      firstVisit: true,
      firstVisitTimer: 0,
      selected: this.default ? this.default : [],
      open: false,
      search: ''
    }
  },
  model: {
    prop: 'default',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default () {
          return ''
      }
    },
    styles: {
      type: String,
      default () {
          return ''
      }
    },
    default: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    searchable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // this.$emit("input", this.selected);
    this.search = this.selected.label
    if (this.default) {
      this.selected = this.default
    }
  },
  watch: {
    default: {
      handler () {
        if (this.default && this.default.label !== '') this.selected = this.default
      },
      deep: true
    },
    search: {
      handler (val) {
        if (this.searchable) {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          if (!this.firstVisit) {
            // this.open = true

            this.options.forEach((option) => {
              if (option.label.indexOf(val) !== -1) option.show = true
              else option.show = false
            })
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.useral-custom-element-multiple-select {
  position: relative;
  width: 100%;
  min-width: 100px;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;

  label {
    font-size: 12px;
    position: absolute;
    z-index: 10000;
    top: -8px;
    left: 8px;
    line-height: 13px;
    padding: 0 5px;
    background: #fff;
  }


  .selected {
    height: 100%;
    position: relative;
    background-color: #555555;
    border-radius: 6px;
    //border: 1px solid #10163a;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    z-index: unset !important;
    transition: background-color .3s ease;

    div {
      width: calc(100% - 35px);
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.open {
      border-radius: 6px 6px 0px 0px;
      z-index: 200 !important;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.danger {
      border: 1px solid rgba(var(--vs-danger), 1) !important;
    }

    &:after {
      position: absolute;
      content: "";
      top: calc(100% / 2);
      right: 1em;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: #cecece transparent transparent transparent;
    }
  }

  .search-box {
    padding: 0 !important;
    position: absolute;
    right: 0;
    left: 0;
    border: 1px solid #cecece !important;
    z-index: 160;

    input {
      padding-left: 45px !important;
      transition: background-color .3s ease;
      background: #ffffff !important;
      border-radius: 0 !important;
    }

    .cancel-search {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 23px;
      margin: auto;
      display: block;
      height: fit-content;
    }

    .search-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 23px;
      margin: auto;
      display: block;
      height: fit-content;
    }
  }

  .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    //border-right: 1px solid #10163a;
    //border-left: 1px solid #10163a;
    //border-bottom: 1px solid #10163a;
    box-shadow: 0px 5px 10px -5px black;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 150;
    max-height: 300px;
    overflow-y: scroll;

    &.searchable {
      top: 95px;
      min-height: 10px;

      div:first-child {
        border-top: none !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 1px;
    }

    &::-webkit-scrollbar {
      width: 1px;
    }

    div {
      color: #fff;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
      border-top: 1px solid #333333;

      &:hover {
        transition: all 1s cubic-bezier(0.39, 0.58, 0.57, 1);
        background-color: #333333;
      }
    }
  }

  .custom-select-overflow {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}

.selectHide {
  display: none;
}
</style>
